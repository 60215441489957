<template>
    <div class="table-content" style="padding: 0">
        <div style="padding-left: 0;">
            <md-button style="margin-left: 0;" v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
        </div>
        <table class="data-table" style="padding: 0">
            <thead>
                <tr>
                    <th style="width:100px;">#</th>
                    <th style="width:100px;">Trạng thái</th>
                    <th style="width:20%;">Người tạm ứng</th>
                    <th style="width:25%;">Mã tạm ứng</th>
                    <th class="right" style="width:15%;">Tổng tạm ứng</th>
                    <th style="width:15%;">Ngày tạm ứng</th>
                    <th style="width:15%;">Hành động</th>
                </tr>
            </thead>
            <tbody v-if="loadding == true" style="height: 150px;">
                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
            </tbody>
            <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
            </tbody>
            <tbody v-if="loadding == false && data.length > 0">
                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                    <td class="center">{{index + 1}}</td>
                    <td class="center">
                        <md-chip v-if="item.status == 0" class="status-2 status-temp">Lưu tạm</md-chip>
                        <md-chip v-if="item.status == 1" class="status-2 status-inprogress">Chờ duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                        <md-chip v-else-if="item.status == 2" class="status-2 status-done">Đã duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                        <md-chip v-else-if="item.status == 3" class="status-2 status-reject">Không duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                        <md-chip v-else-if="item.status == 4" class="status-2 status-edit">Yêu cầu chỉnh sửa ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                    </td>
                    <td class="center">{{item.staff.fullName}}</td>
                    <td class="center">{{item.advancePaymentCode}}</td>
                    <td class="right">{{ formatNumber(item.amount)}}</td>
                    <td class="center">{{item.advancePaymentDate}}</td>
                    <td class="action">
                        <md-button @click="view(item.id)" class="md-fab md-mini md-view">
                            <i class="uil uil-eye"></i>
                            <md-tooltip>Xem</md-tooltip>
                        </md-button>
                        <md-button @click="edit(item.id)" class="md-fab md-mini md-second">
                            <md-icon>edit</md-icon>
                            <md-tooltip>Sửa</md-tooltip>
                        </md-button>
                        <md-button v-if="item.status == 1" v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                            <md-icon>clear</md-icon>
                            <md-tooltip>Xóa</md-tooltip>
                        </md-button>
                        <md-button v-else disabled class="md-fab md-mini md-plain">
                            <md-icon>clear</md-icon>
                            <md-tooltip>Xóa</md-tooltip>
                        </md-button>
                    </td>
                </tr>
            </tbody>
        </table>
        <crudForm ref="crudForm" @refresh="refresh" />
    </div>
</template>
<script>
    import projectAdvancePaymentService from '@/api/projectAdvancePaymentService';
    import messageBox from '@/utils/messageBox';
    import { mapActions } from 'vuex';
    import crudForm from "../project-advance-payment/Crud.vue"

    export default {
        props: ["project"],
        components: {
            crudForm,
        },
        data() {
            return {
                loadding: false,
                data: [],
                id: 0,
                projectId: 0
            }
        },
        created(){
            this.getData();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            refresh(){
                this.getData();
            },

            filter(){
                this.getData();
            },

            getData(){
                this.loadding = true;
                this.projectId = this.$route.params.id;
                projectAdvancePaymentService.getAll(this.projectId).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                projectAdvancePaymentService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            add(){
                this.$refs.crudForm.open(0, this.order);
            },

            edit(id){
                this.$refs.crudForm.open(id, this.order);
            },

            explain(id){
                this.$refs.crudForm.openExplain(id);
            },
            
            invoice(id){
                this.$refs.crudInvoice.open(id);
            },

            view(id){
                this.$router.push('/project-advance-payment/view/' + id);
            }
        }
    }

</script>
