<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 's']" @shortkey="submit(true)" @click="submit(true)" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu thông tin (Ctrl + S)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <md-tabs>
                        <md-tab @click="onTabClick('tab-info')" id="tab-info" class="tab-content" md-label="Thông tin dự án">
                            <div class="row">
                                <div class="col l-3 m-3 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.branchId.$error }">
                                        <label for="branchId">Chi nhánh<span class="label-require">(*)</span></label>
                                        <md-select v-model="entity.branchId" name="branchId" id="branchId">
                                            <md-option value="0">Chọn chi nhánh</md-option>
                                            <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="submitted && !$v.entity.branchId.isSelected">Vui lòng chọn chi nhánh</span>
                                    </md-field>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.projectCode.$error }">
                                        <label for="projectCode">Mã dự án<span class="label-require">(*)</span></label>
                                        <md-input name="projectCode" v-model="entity.projectCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.projectCode.required">Vui lòng nhập mã dự án</span>
                                    </md-field>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.categoryId.$error }">
                                        <label for="categoryId">Giai đoạn<span class="label-require">(*)</span></label>
                                        <md-select v-model="entity.categoryId" name="categoryId" id="categoryId">
                                            <md-option value="0">Chọn giai đoạn</md-option>
                                            <md-option v-for="item in categories" :key="item.id" :value="item.id">{{item.categoryName}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="submitted && !$v.entity.categoryId.isSelected">Vui lòng chọn giai đoạn</span>
                                    </md-field>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <md-datepicker v-model="entity.startDate" :md-model-type="String" md-immediately :class="{'md-invalid': submitted && $v.entity.startDate.$error }">
                                        <label>Ngày bắt đầu</label>
                                        <span class="md-error" v-if="submitted && !$v.entity.startDate.required">Vui lòng chọn bắt đầu</span>
                                    </md-datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-3 m-3 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.projectName.$error }">
                                        <label for="projectName">Tên dự án<span class="label-require">(*)</span></label>
                                        <md-input name="projectName" v-model="entity.projectName"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.projectName.required">Vui lòng nhập tên dự án</span>
                                    </md-field>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers" :class="{'md-invalid': submitted && $v.entity.customerId.$error }">
                                            <label>Khách hàng<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.customerId.isSelected">Vui lòng chọn khách hàng</span>
                                        </md-autocomplete>
                                        <md-button @click="openCustomer()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <md-field>
                                        <label for="contractName">Ghi chú</label>
                                        <md-input name="filePath" v-model="entity.note"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <md-datepicker v-model="entity.endDate" md-immediately :md-model-type="String">
                                        <label>Ngày kết thúc</label>
                                    </md-datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-tabs @md-changed="changedTab">
                                        <md-tab id="tab-contract" class="tab-contract" md-label="Hợp đồng" style="padding: 0 !important;">
                                            <div class="tool-bar" style="margin-top: 10px;">
                                                <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                            </div>
                                            <table class="data-table-2"> 
                                                <thead> 
                                                    <tr> 
                                                        <th style="width:100px;">#</th> 
                                                        <th style="width:25%;">Mã hợp đồng</th> 
                                                        <th style="width:30%;">Tên hợp đồng</th> 
                                                        <th style="width:30%;">Phụ trách</th> 
                                                        <th style="width:10%">Hành động</th> 
                                                    </tr> 
                                                </thead> 
                                                <tbody v-if="loadding == true" style="height: 100px;"> 
                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                </tbody>
                                                <tbody v-if="loadding == false"> 
                                                    <tr v-for="(item, index) in entity.contracts" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                        <td class="center">{{index + 1}}</td> 
                                                        <td class="center">
                                                            <div class="grid-control">
                                                                <input v-model="item.contractCode" class="form-control" type="text">
                                                                <md-button @click="openContract(item.id)" class="md-icon-button">
                                                                    <md-icon>manage_search</md-icon>
                                                                    <md-tooltip>Tìm hợp đồng</md-tooltip>
                                                                </md-button>
                                                            </div>
                                                        </td> 
                                                        <td class="center">{{item.contractName}}</td> 
                                                        <td class="center">{{item.staff.fullName}}</td> 
                                                        <td class="grid-action">
                                                            <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                        </td> 
                                                    </tr> 
                                                </tbody> 
                                            </table> 
                                        </md-tab>
                                        <md-tab id="tab-team" class="tab-content" md-label="Thành viên dự án" style="padding: 0 !important;">
                                            <div class="tool-bar" style="margin-top: 10px;">
                                                <md-button @click="addMemberRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                            </div>
                                            <table class="data-table-2"> 
                                                <thead> 
                                                    <tr>
                                                        <th style="width:100px;">#</th> 
                                                        <th style="width:20%;">Bộ phận/phòng</th> 
                                                        <th style="width:20%;">Tên nhân sự</th> 
                                                        <th style="width:15%;">Vị trí</th>
                                                        <th style="width:15%;">Lead</th>
                                                        <th style="width:10%;">Ngày bắt đầu</th> 
                                                        <th style="width:10%;">Ngày kết thúc</th> 
                                                        <th style="width:150px">Hành động</th> 
                                                    </tr> 
                                                </thead> 
                                                <tbody v-if="loadding == true" style="height: 100px;"> 
                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                </tbody>
                                                <tbody v-if="loadding == false"> 
                                                    <tr v-for="(item, index) in entity.team" :key="'team-' + item.member.fullName" :class="{ 'odd': index % 2 !== 0 }"> 
                                                        <td class="center">{{index + 1}}</td>
                                                        <td class="center">
                                                            <div class="grid-control">
                                                                <input v-model="item.dept.name" class="form-control" type="text">
                                                                <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                    <md-icon>manage_search</md-icon>
                                                                    <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                </md-button>
                                                            </div>
                                                        </td> 
                                                        <td class="center">
                                                            <div class="grid-control">
                                                                <input v-model="item.member.fullName" class="form-control" type="text">
                                                                <md-button @click="openMember(item.id)" class="md-icon-button">
                                                                    <md-icon>manage_search</md-icon>
                                                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                </md-button>
                                                            </div>
                                                        </td> 
                                                        <td class="center">
                                                            <div class="group-control">
                                                                <input v-model="item.position" class="form-control" type="text">
                                                            </div>
                                                        </td>
                                                        <td class="center">
                                                            <div class="radio-group" style="padding: 0 10px; text-align: center;">
                                                                <md-radio v-model="item.lead" :value="true">Lead</md-radio>
                                                                <md-radio v-model="item.lead" :value="false">Member</md-radio>
                                                            </div>
                                                        </td>
                                                        <td class="center">
                                                            <div class="group-control">
                                                                <md-datepicker v-model="item.startDate" md-immediately :md-model-type="String"></md-datepicker>
                                                            </div>
                                                        </td> 
                                                        <td class="center">
                                                            <div class="group-control">
                                                                <md-datepicker v-model="item.endDate" md-immediately :md-model-type="String"></md-datepicker>
                                                            </div>
                                                        </td> 
                                                        <td class="grid-action">
                                                            <a @click="delMemberRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                        </td> 
                                                    </tr> 
                                                </tbody> 
                                            </table> 
                                        </md-tab>
                                    </md-tabs>
                                </div>
                            </div>
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" id="tab-budget" class="tab-content" md-label="Dự trù kinh phí">
                            <budgetList :project="entity" />
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" id="tab-estimate" class="tab-content" md-label="Dự toán dự án">
                            <estimateList :project="entity" />
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" id="tab-order" class="tab-content" md-label="Đơn hàng">
                            <orderList :project="entity" />
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" id="tab-advance-payment" class="tab-content" md-label="Tạm ứng">
                            <advancePaymentList :project="entity" />
                        </md-tab>
                    </md-tabs>
                </div>
            </div>
        </div>
        <md-dialog-alert :md-active.sync="dataCheck" md-title="Thông báo!" md-content="Bạn phải thêm mới dự án trước" />
        <contractList ref="contractList" @close="closeContract"/>
        <customerList ref="customerList" title="khách hàng" :type="companyType" @close="closeCustomer"/>
        <memberList ref="memberList" @close="closeMember"/>
        <deptList ref="deptList" @close="closeDept"/>
    </div>
</template>

<script>
    import projectService from '../../../api/projectService';
    import branchService from '../../../api/branchService';
    import categoryService from '../../../api/categoryService';
    import companyService from '../../../api/companyService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import contractList from '../../../components/popup/_ContractList.vue';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import deptList from '../../../components/popup/_DepartmentList.vue';
    import memberList from '../../../components/popup/_StaffList.vue';
    import estimateList from './EstimateList.vue'
    import budgetList from './BudgetList.vue'
    import orderList from './OrderList.vue'
    import advancePaymentList from './AdvancePaymentList.vue'

    export default ({
        components: {
            orderList,
            contractList,
            customerList,
            deptList,
            memberList,
            estimateList,
            budgetList,
            advancePaymentList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật dự án'
        },
        data() {
            return {
                title: '',
                id: 0,
                dataCheck: false,
                submitted: false,
                loadding: false,
                group: common.groupCode.project,
                companyType: common.companyType.customer,
                entity: { id: 0, branchId: 0, categoryId: 0, team: [], customer: {}, customerId: 0, typeId: 2, contracts: [], projectCode: '', projectName: '', typeCode: '', startDate: null, endDate: null, isActive: false },
                customerName: '',
                customers: [],
                member: {id: 'id_' + common.getFakeId(), memberId: 0, dept: { code: '', name: '' }, deptId: 0, member: { fullName: '', code: '' }, position: 'Thành viên dự án', lead: 0, startDate: '', endDate: '', status: 1},
                contract: { id: 'id_' + common.getFakeId(), typeId: 2, customer: { companyAlias: '' }, staff: { fullName: '' }, contractCode: '', contractName: ''},
                orderType: 2,
                branchs: [],
                categories: []
            }
        },
        created(){
            this.id = this.$route.params.id;
            this.getBranchs();
            this.getCategories();
            if(this.id > 0){
               this.title = 'Cập nhật dự án hàng STST';
               this.getById();
            }
            else{
                this.title = 'Thêm mới dự án hàng STST';
                this.entity.contracts.push(this.contract);
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            changedTab(activeTab){
                if(activeTab == 'tab-insurance'){
                    console.log('');
                }
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.entity.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.entity.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.customer, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Member
            delMemberRow(item){
                const index = this.entity.team.findIndex(x => x.id == item.id);
                this.entity.team.splice(index, 1);
            },

            addMemberRow(){
                let item = { id: 'id_' + common.getFakeId(), status: 1, dept: { code: '', name: '' }, deptId: 0, member: { fullName: '', code: '' }, memberId: 0, position: 'Thành viên dự án', startDate: common.dateNow, endDate: '', lead: true };
                this.entity.team.push(item);
            },

            closeMember(item){
                const selected = this.entity.team.findIndex(x => x.id == this.selectedId);
                this.entity.team[selected].member = item;
                this.entity.team[selected].memberId = item.id;
                this.$refs.memberList.close();
                this.selectedId = '';
            },

            openMember(id){
                this.selectedId = id;
                const index = this.entity.team.findIndex(x => x.id == id);
                this.deptId = this.entity.team[index].deptId;
                this.$refs.memberList.open(this.deptId);
            },

            //DEPT
            closeDept(item){
                const selected = this.entity.team.findIndex(x => x.id == this.selectedId);
                this.entity.team[selected].dept = item;
                this.entity.team[selected].deptId = item.id;
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },
            
            //Contract
            delRow(item){
                const index = this.entity.contracts.findIndex(x => x.id == item.id);
                this.entity.contracts.splice(index, 1);
            },

            addRow(){
                let item = { id: 'id_' + common.getFakeId(), customer: {}, staff: {}, contractCode: '', contractName: ''};
                this.entity.contracts.push(item);
            },

            closeContract(item){
                const selected = this.entity.contracts.findIndex(x => x.id == this.selectedId);
                this.entity.contracts[selected].id = item.id;
                this.entity.contracts[selected].contractCode = item.contractCode;
                this.entity.contracts[selected].contractName = item.contractName;
                this.entity.contracts[selected].customer = item.customer;
                this.entity.contracts[selected].staff = item.staff;
                this.$refs.contractList.close();
                this.selectedId = '';
            },

            openContract(id){
                this.selectedId = id;
                this.$refs.contractList.open(this.entity.customerId, this.orderType);
            },

            submit(isActive){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.contracts !== undefined && this.entity.contracts.length > 0){
                    this.entity.contracts = this.entity.contracts.filter(item => !(item.contractCode === ''));
                    this.entity.contracts.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if (this.entity.contracts === undefined || this.entity.contracts.length === 0){
                    messageBox.showWarning("Vui lòng thêm hợp đồng");
                    return;
                }
                if(this.entity.team !== undefined && this.entity.team.length > 0){
                    this.entity.team.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                this.entity.isActive = isActive;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                this.entity.orderType = parseFloat(this.orderType);
                projectService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push("/project-heavy/crud/" + response.data.id);
                        this.id = response.data.id;
                        this.getById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                projectService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.getById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                projectService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.customerName = response.data.customer.companyAlias;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            
            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getCategories(){
                this.setLoading(true);
                categoryService.getAll(7).then((response) => {
                    if(response.statusCode == 200){
                        this.categories = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/project-heavy');
            }
        },
        watch: { 
            customerName: function (val) { 
                if(val == ''){
                    this.entity.customerId = 0;
                }
            },
        },
        validations: {
            entity: {
                branchId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                customerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                categoryId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                projectCode: { required },
                projectName: { required },
                startDate: { required },
            }
        }
    })

</script>
