<template>
    <div class="table-content" style="padding: 0">
        <div style="padding-left: 0;">
            <md-button style="margin-left: 0;" v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
        </div>
        <table class="data-table" style="padding: 0">
            <thead>
                <tr>
                    <th style="width:100px;">#</th>
                    <th class="left" style="width:15%;">Mã đơn hàng</th>
                    <th class="left" style="width:15%;">Hợp đồng</th>
                    <th class="left" style="width:20%;">Khách hàng</th>
                    <th class="left" style="width:15%;">Nhân viên</th>
                    <th style="width:15%;">Ngày tạo</th>
                    <th style="width:15%;">Trạng thái</th>
                    <th style="width:150px;">Hành động</th>
                </tr>
            </thead>
            <tbody v-if="loadding == true" style="height: 150px;">
                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
            </tbody>
            <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
            </tbody>
            <tbody v-if="loadding == false && data.length > 0">
                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                    <td class="center">{{index + 1}}</td>
                    <td>{{item.orderCode}}</td>
                    <td>{{item.contract.contractCode}}</td>
                    <td>{{item.customer.companyName}}</td>
                    <td>{{item.staff.fullName}}</td>
                    <td class="center">{{item.orderDate}}</td>
                    <td class="center">
                        <md-chip v-if="item.status > 0" class="status-3 status-active">Hoạt động</md-chip>
                        <md-chip v-else class="status-3 status-temp">Lưu tạm</md-chip>
                    </td>
                    <td class="action">
                        <router-link :to="'/order-heavy/crud/' + item.id + '?projectId=' + projectId">
                            <md-button class="md-fab md-mini md-second">
                                <md-icon>edit</md-icon>
                                <md-tooltip>Sửa</md-tooltip>
                            </md-button>
                        </router-link>
                        <md-button v-if="item.status > 0" v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                            <md-icon>clear</md-icon>
                            <md-tooltip>Xóa</md-tooltip>
                        </md-button>
                        <md-button v-else disabled class="md-fab md-mini md-plain">
                            <md-icon>clear</md-icon>
                            <md-tooltip>Xóa</md-tooltip>
                        </md-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import orderHeavyService from '../../../api/orderHeavyService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';

    export default {
        props: ["project"],
        data() {
            return {
                loadding: false,
                data: [],
                id: 0,
                projectId: 0
            }
        },
        created(){
            this.getData();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            getData(){
                this.loadding = true;
                this.projectId = this.$route.params.id;
                orderHeavyService.getAll(this.projectId).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                orderHeavyService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            add(){
                this.$router.push('/order-heavy/crud?projectId=' + this.projectId);
            },
        },
        watch: {
            
        }
    }
</script>
